import { GetServerSideProps } from 'next';
import { handleAndGetTenantAsync, PageBaseProps } from '../../../../utils/tenants';
import AuthLayout from '../../../../components/layouts/AuthLayout';
import NotConfiguredBody from './not-configured';

type Props = PageBaseProps & {

}

export default function ForgotPasswordPage(props: Props) {
    return <AuthLayout>
        <NotConfiguredBody />
    </AuthLayout>
}

export const getServerSideProps: GetServerSideProps<Props> = async (ctx) => {
    const { tenant, auth } = await handleAndGetTenantAsync(ctx);

    if (!auth.isFromAllowedIP) {
        return {
            notFound: true
        }
    }

    if (auth.isAuthenticated) {
        return {
            redirect: {
                permanent: false,
                destination: "/",
            },
            props: {},
        };
    }

    return {
        props: {
            tenant, auth
        }
    }
}