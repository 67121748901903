import clsx from "clsx";
import React, { ButtonHTMLAttributes } from "react";

type FormButtonProps = {
    children?: React.ReactNode
} & ButtonHTMLAttributes<HTMLButtonElement>

export default React.memo(FormButton);

function FormButton({ children, ...rest }: FormButtonProps) {

    return <button {...rest}
        type="submit"
        className={clsx("disabled:opacity-50 bg-primary-bg group h-12 w-full",
            "select-none rounded-sm border px-2 py-2.5 text-center text-sm font-medium",
            "text-white transition duration-200 focus:outline-none relative",
            rest.className ?? "")}>
        <span className={clsx("spinner-grow spinner-grow-sm hidden mx-auto",
            "group-disabled:visible group-disabled:inline-block")}></span>
        {children}
    </button>
}