import Head from 'next/head';
import { useAppContext } from '../../frontend/AppContext';
import { getFileUrl } from '../../utils/imaging';
import { GetStaticProps } from 'next';
import clsx from 'clsx';
import Link from 'next/link';

type Props = {
    children: React.ReactNode
}

export default function AuthLayout({ children }: Props) {

    const tenantCtx = useAppContext();

    return (<>
        <Head>
            <title>{tenantCtx?.tenant?.texts.announcementsMenuLinkText} - {tenantCtx?.tenant?.title}</title>
            <link rel='icon' datatype='image/png' href={getFileUrl(tenantCtx?.tenant?.icons.favIconFileId)} />
            <meta property="og:image" content={getFileUrl(tenantCtx?.tenant?.icons.logoFileId)}></meta>
        </Head>

        <div className='my-auto mx-auto h-100 h-full flex justify-center relative'>
            <div className='absolute right-12 top-7 cursor-pointer'>
                <Link href="/">
                    {/* {tenantCtx?.tenant?.texts.authHomeNavigation ?? "Home"} */}

                    <i className={clsx("fa-solid fa-house text-xl hover:drop-shadow-xl",
                        "bg-primary-text opacity-70 hover:opacity-100 transition-all duration-500")}></i>
                </Link>
            </div>
            <div className='mx-auto my-auto flex w-full'>
                <div className="flex w-full flex-col items-center justify-center px-6 py-8 md:px-8">
                    <div id="container" className={clsx("flex h-auto w-full flex-col overflow-hidden",
                        "rounded-lg border border-gray-100 bg-white shadow-lg sm:max-w-sm md:mt-0")}>
                        <div className="flex h-auto flex-col p-6 font-sans sm:p-8 relative">
                            <div className="flex flex-col content-center space-y-2 py-3">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export const getStaticProps: GetStaticProps = async () => {
    return { notFound: true };
};